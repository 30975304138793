var header = function () {
    // ===================================== BEGIN: MOBILE MENU =====================================================
    // Open mobile menu
    var openMobileMenu = function () {
        $('body').removeClass('overflow-y-auto')
        $('body').addClass('overflow-y-hidden')
        $('#mobileMenuWrapper').slideDown('quick', function () {
            $('#mobileMenuWrapper').removeClass('hidden')
        })
        $('#mobileMenuTriggerBtn .open-icon').fadeOut('quick', function () {
            $('#mobileMenuTriggerBtn .open-icon').addClass('hidden')
            $('#mobileMenuTriggerBtn .close-icon').fadeIn('quick', function () {
                $('#mobileMenuTriggerBtn .close-icon').removeClass('hidden')
            })
        })
    }

    // Close mobile menu
    var closeMobileMenu = function () {
        $('body').removeClass('overflow-y-hidden')
        $('body').addClass('overflow-y-auto')
        $('#mobileMenuWrapper').slideUp('quick', function () {
            $('#mobileMenuWrapper').addClass('hidden')
            var showSubMobileMenuItem = $('#mobileMenuWrapper .show-sub')
            if (showSubMobileMenuItem.length) {
                var currentSubMenuWrapper = showSubMobileMenuItem.find('.mobile-sub-menu-wrapper')
                showSubMobileMenuItem.removeClass('show-sub')
                currentSubMenuWrapper.fadeOut('quick', function () {
                    currentSubMenuWrapper.addClass('hidden')
                    showSubMobileMenuItem.siblings().fadeIn('quick', function () {})
                })
            }
        })
        $('#mobileMenuTriggerBtn .close-icon').fadeOut('quick', function () {
            $('#mobileMenuTriggerBtn .close-icon').addClass('hidden')
            $('#mobileMenuTriggerBtn .open-icon').fadeIn('quick', function () {
                $('#mobileMenuTriggerBtn .open-icon').removeClass('hidden')
            })
        })
    }

    // Hide mobile menu
    var hideMobileMenu = function () {
        $('body').removeClass('overflow-y-hidden')
        $('body').addClass('overflow-y-auto')
        $('#mobileMenuWrapper').fadeOut('quick', function () {
            $('#mobileMenuWrapper').addClass('hidden')
            var showSubMobileMenuItem = $('#mobileMenuWrapper .show-sub')
            if (showSubMobileMenuItem.length) {
                var currentSubMenuWrapper = showSubMobileMenuItem.find('.mobile-sub-menu-wrapper')
                showSubMobileMenuItem.removeClass('show-sub')
                currentSubMenuWrapper.fadeOut('quick', function () {
                    currentSubMenuWrapper.addClass('hidden')
                    showSubMobileMenuItem.siblings().fadeIn('quick', function () {})
                })
            }
        })
        $('#mobileMenuTriggerBtn .close-icon').fadeOut('quick', function () {
            $('#mobileMenuTriggerBtn .close-icon').addClass('hidden')
            $('#mobileMenuTriggerBtn .open-icon').fadeIn('quick', function () {
                $('#mobileMenuTriggerBtn .open-icon').removeClass('hidden')
            })
        })
    }

    // Mobile menu
    $('#mobileMenuTriggerBtn').click(function (e) {
        if ($('#mobileMenuWrapper').hasClass('hidden')) {
            openMobileMenu()
        } else {
            var showSubMobileMenuItem = $('#mobileMenuWrapper .show-sub')
            if (showSubMobileMenuItem.length) {
                var currentSubMenuWrapper = showSubMobileMenuItem.find('.mobile-sub-menu-wrapper')
                showSubMobileMenuItem.removeClass('show-sub')
                currentSubMenuWrapper.slideUp('quick', function () {
                    currentSubMenuWrapper.addClass('hidden')
                    showSubMobileMenuItem.siblings().slideDown('quick', function () {})
                })
            } else {
                closeMobileMenu()
            }
        }
    })

    // Mobile menu
    $('#closeMobileMenuTriggerBtn').click(function () {
        var showSubMobileMenuItem = $('#mobileMenuWrapper .show-sub')
        if (showSubMobileMenuItem.length) {
            var currentSubMenuWrapper = showSubMobileMenuItem.find('.mobile-sub-menu-wrapper')
            showSubMobileMenuItem.removeClass('show-sub')
            currentSubMenuWrapper.slideUp('quick', function () {
                currentSubMenuWrapper.addClass('hidden')
                showSubMobileMenuItem.siblings().slideDown('quick', function () {})
            })
        } else {
            closeMobileMenu()
        }
    })

    // Mobile menu
    $('#mobileMenuWrapper .mobile-menu-item').click(function () {
        var currentMenuItem = $(this)
        var currentSubMenuWrapper = currentMenuItem.find('.mobile-sub-menu-wrapper')
        if (currentMenuItem.hasClass('show-sub')) {
            currentMenuItem.removeClass('show-sub')
            currentSubMenuWrapper.slideUp('quick', function () {
                currentSubMenuWrapper.addClass('hidden')
                currentMenuItem.siblings().slideDown('quick', function () {})
            })
        } else {
            currentMenuItem.siblings().slideUp('quick', function () {
                currentMenuItem.addClass('show-sub')
                currentSubMenuWrapper.slideDown('quick', function () {
                    currentSubMenuWrapper.removeClass('hidden')
                })
            })
        }
    })
    // ===================================== END:   MOBILE MENU =====================================================

    // Desktop mega menu
    var megaMenuCarousel = $('#megaMenuCarousel')
    if (megaMenuCarousel.length) {
        var megaMenuCarouselInstance = megaMenuCarousel.owlCarousel({
            nav: false,
            dots: false,
            loop: true,
            autoplay: false,
            autoplayHoverPause: true,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                },
            },
        })
    }

    // Hover menu item
    $('.menu-item').hover(function (e) {
        if ($('#expandMegaMenuContainer').hasClass('hidden')) {
            $('#expandMegaMenuContainer').slideDown(function () {
                $('#expandMegaMenuContainer').removeClass('hidden')
                $('.sub-menu-item').removeClass('hidden')
            })
        }

        var id = $(this).attr('id')
        if (id === 'introMenu') {
            megaMenuCarouselInstance.trigger('to.owl.carousel', [0, 0])
        } else if (id === 'methodMenu') {
            megaMenuCarouselInstance.trigger('to.owl.carousel', [1, 0])
        } else if (id === 'trainingMenu') {
            megaMenuCarouselInstance.trigger('to.owl.carousel', [2, 0])
        } else if (id === 'serviceMenu') {
            megaMenuCarouselInstance.trigger('to.owl.carousel', [3, 0])
        } else if (id === 'sharesMenu') {
            megaMenuCarouselInstance.trigger('to.owl.carousel', [4, 0])
        } else if (id === 'contactMenu') {
            megaMenuCarouselInstance.trigger('to.owl.carousel', [5, 0])
        } else {
            megaMenuCarouselInstance.trigger('to.owl.carousel', [0, 0])
        }
    })

    // Click menu item
    $('.menu-item').mouseup(function (e) {
        if ($('#expandMegaMenuContainer').hasClass('hidden')) {
            $('#expandMegaMenuContainer').slideDown(function () {
                $('#expandMegaMenuContainer').removeClass('hidden')
                $('.sub-menu-item').removeClass('hidden')
            })
        }

        var id = $(this).attr('id')
        if (id === 'introMenu') {
            megaMenuCarouselInstance.trigger('to.owl.carousel', [0, 0])
        } else if (id === 'methodMenu') {
            megaMenuCarouselInstance.trigger('to.owl.carousel', [1, 0])
        } else if (id === 'trainingMenu') {
            megaMenuCarouselInstance.trigger('to.owl.carousel', [2, 0])
        } else if (id === 'serviceMenu') {
            megaMenuCarouselInstance.trigger('to.owl.carousel', [3, 0])
        } else if (id === 'sharesMenu') {
            megaMenuCarouselInstance.trigger('to.owl.carousel', [4, 0])
        } else if (id === 'contactMenu') {
            megaMenuCarouselInstance.trigger('to.owl.carousel', [5, 0])
        } else {
            megaMenuCarouselInstance.trigger('to.owl.carousel', [0, 0])
        }
    })

    // Hover outside menu - content wrapper
    $('.content-wrapper').hover(function (e) {
        // Close desktop menu
        var container = $('#headerMenuWrapper')
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            if (!$('#expandMegaMenuContainer').hasClass('hidden')) {
                $('#expandMegaMenuContainer').slideUp(function () {
                    $('#expandMegaMenuContainer').addClass('hidden')
                })
            }
        }

        // Close account menu
        var account = $('#accountMenuWrapper')
        if (!account.is(e.target) && account.has(e.target).length === 0) {
            if (!$('#accountMenuWrapper').hasClass('hidden')) {
                $('#accountMenuWrapper').slideUp(function () {
                    $('#accountMenuWrapper').addClass('hidden')
                })
            }
        }
    })

    // Hover outside menu - logo wrapper
    $('#logoWrapper').hover(function (e) {
        // Close desktop menu
        if (!$('#expandMegaMenuContainer').hasClass('hidden')) {
            $('#expandMegaMenuContainer').slideUp(function () {
                $('#expandMegaMenuContainer').addClass('hidden')
            })
        }

        // Close account menu
        if (!$('#accountMenuWrapper').hasClass('hidden')) {
            $('#accountMenuWrapper').slideUp(function () {
                $('#accountMenuWrapper').addClass('hidden')
            })
        }
    })

    // Hover outside menu - menu wrapper
    $('#menuWrapper').hover(function (e) {
        // Close account menu
        if (!$('#accountMenuWrapper').hasClass('hidden')) {
            $('#accountMenuWrapper').slideUp(function () {
                $('#accountMenuWrapper').addClass('hidden')
            })
        }
    })

    // Hover outside menu - account wrapper
    $('#accountWrapper').hover(function (e) {
        // Close desktop menu
        if (!$('#expandMegaMenuContainer').hasClass('hidden')) {
            $('#expandMegaMenuContainer').slideUp(function () {
                $('#expandMegaMenuContainer').addClass('hidden')
            })
        }
        // Open account menu
        if ($('#accountMenuWrapper').hasClass('hidden')) {
            $('#accountMenuWrapper').slideDown(function () {
                $('#accountMenuWrapper').removeClass('hidden')
            })
        }
    })

    // Mouse up - Document
    $(document).mouseup(function (e) {
        // Close mobile menu
        var mobileMenuWrapper = $('#mobileMenuWrapper')
        if (!mobileMenuWrapper.is(e.target) && mobileMenuWrapper.has(e.target).length === 0) {
            var mobileMenuTriggerBtn = $('#mobileMenuTriggerBtn')
            if (!mobileMenuTriggerBtn.is(e.target) && mobileMenuTriggerBtn.has(e.target).length === 0) {
                hideMobileMenu()
            }
        }

        // Close desktop menu
        var headerMenuWrapper = $('#headerMenuWrapper')
        if (!headerMenuWrapper.is(e.target) && headerMenuWrapper.has(e.target).length === 0) {
            if (!$('#expandMegaMenuContainer').hasClass('hidden')) {
                $('#expandMegaMenuContainer').slideUp(function () {
                    $('#expandMegaMenuContainer').addClass('hidden')
                })
            }
        }

        // Open/Close account menu
        var accountWrapper = $('#accountWrapper')
        if (!accountWrapper.is(e.target) && accountWrapper.has(e.target).length === 0) {
            if (!$('#accountMenuWrapper').hasClass('hidden')) {
                $('#accountMenuWrapper').slideUp(function () {
                    $('#accountMenuWrapper').addClass('hidden')
                })
            }
        } else {
            if ($('#accountMenuWrapper').hasClass('hidden')) {
                $('#accountMenuWrapper').slideDown(function () {
                    $('#accountMenuWrapper').removeClass('hidden')
                })
            }
        }
    })
}

export default header
