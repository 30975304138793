var training = function () {
    // Feedback Carousel
    var feedbackCarousel = $('#feedbackCarousel')
    if (feedbackCarousel.length) {
        var feedbackCarouselInstance = feedbackCarousel.owlCarousel({
            nav: false,
            dots: true,
            loop: true,
            autoplay: true,
            autoplayHoverPause: true,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                },
                640: {
                    items: 1,
                },
                768: {
                    items: 1,
                },
                1024: {
                    items: 2,
                },
                1280: {
                    items: 2,
                },
            },
        })
        feedbackCarousel.parent('.carousel-wrapper').find('.carousel-nav').removeClass('hidden')
        feedbackCarousel
            .parent('.carousel-wrapper')
            .find('.carousel-prev')
            .click(function () {
                feedbackCarouselInstance.trigger('prev.owl.carousel')
            })
        feedbackCarousel
            .parent('.carousel-wrapper')
            .find('.carousel-next')
            .click(function () {
                feedbackCarouselInstance.trigger('next.owl.carousel')
            })
    }
}

export default training
