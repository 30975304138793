var service = function () {
    // Top service Carousel
    var soulPlanBasicServicesCarousel = $('#soulPlanBasicServicesCarousel')
    if (soulPlanBasicServicesCarousel.length) {
        var soulPlanBasicServicesCarouselInstance = soulPlanBasicServicesCarousel.owlCarousel({
            nav: false,
            dots: true,
            loop: true,
            autoplay: true,
            autoplayHoverPause: true,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                },
                640: {
                    items: 2,
                },
                768: {
                    items: 2,
                },
                1024: {
                    items: 3,
                },
                1280: {
                    items: 3,
                },
            },
        })
        soulPlanBasicServicesCarousel.parent('.carousel-wrapper').find('.carousel-nav').removeClass('hidden')
        soulPlanBasicServicesCarousel
            .parent('.carousel-wrapper')
            .find('.carousel-prev')
            .click(function () {
                soulPlanBasicServicesCarouselInstance.trigger('prev.owl.carousel')
            })
        soulPlanBasicServicesCarousel
            .parent('.carousel-wrapper')
            .find('.carousel-next')
            .click(function () {
                soulPlanBasicServicesCarouselInstance.trigger('next.owl.carousel')
            })
    }

    // Top service Carousel
    var soulPlanAdvanceServicesCarousel = $('#soulPlanAdvanceServicesCarousel')
    if (soulPlanAdvanceServicesCarousel.length) {
        var soulPlanAdvanceServicesCarouselInstance = soulPlanAdvanceServicesCarousel.owlCarousel({
            nav: false,
            dots: true,
            loop: true,
            autoplay: true,
            autoplayHoverPause: true,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                },
                640: {
                    items: 2,
                },
                768: {
                    items: 2,
                },
                1024: {
                    items: 3,
                },
                1280: {
                    items: 3,
                },
            },
        })
        soulPlanAdvanceServicesCarousel.parent('.carousel-wrapper').find('.carousel-nav').removeClass('hidden')
        soulPlanAdvanceServicesCarousel
            .parent('.carousel-wrapper')
            .find('.carousel-prev')
            .click(function () {
                soulPlanAdvanceServicesCarouselInstance.trigger('prev.owl.carousel')
            })
        soulPlanAdvanceServicesCarousel
            .parent('.carousel-wrapper')
            .find('.carousel-next')
            .click(function () {
                soulPlanAdvanceServicesCarouselInstance.trigger('next.owl.carousel')
            })
    }

    // Feedback Carousel
    var feedbackCarousel = $('#feedbackCarousel')
    if (feedbackCarousel.length) {
        var feedbackCarouselInstance = feedbackCarousel.owlCarousel({
            nav: false,
            dots: true,
            loop: true,
            autoplay: true,
            autoplayHoverPause: true,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                },
                640: {
                    items: 1,
                },
                768: {
                    items: 1,
                },
                1024: {
                    items: 2,
                },
                1280: {
                    items: 2,
                },
            },
        })
        feedbackCarousel.parent('.carousel-wrapper').find('.carousel-nav').removeClass('hidden')
        feedbackCarousel
            .parent('.carousel-wrapper')
            .find('.carousel-prev')
            .click(function () {
                feedbackCarouselInstance.trigger('prev.owl.carousel')
            })
        feedbackCarousel
            .parent('.carousel-wrapper')
            .find('.carousel-next')
            .click(function () {
                feedbackCarouselInstance.trigger('next.owl.carousel')
            })
    }
}

export default service
