var home = function () {
    // Experts Carousel
    var expertsCarousel = $('#expertsCarousel')
    if (expertsCarousel.length) {
        var expertsCarouselInstance = expertsCarousel.owlCarousel({
            nav: false,
            dots: true,
            loop: true,
            autoplay: true,
            autoplayHoverPause: true,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                },
                640: {
                    items: 2,
                },
                768: {
                    items: 2,
                },
                1024: {
                    items: 3,
                },
                1280: {
                    items: 4,
                },
            },
        })
        expertsCarousel.parent('.carousel-wrapper').find('.carousel-nav').removeClass('hidden')
        expertsCarousel
            .parent('.carousel-wrapper')
            .find('.carousel-prev')
            .click(function () {
                expertsCarouselInstance.trigger('prev.owl.carousel')
            })
        expertsCarousel
            .parent('.carousel-wrapper')
            .find('.carousel-next')
            .click(function () {
                expertsCarouselInstance.trigger('next.owl.carousel')
            })
    }

    // Top service Carousel
    var topServicesCarousel = $('#topServicesCarousel')
    if (topServicesCarousel.length) {
        var topServicesCarouselInstance = topServicesCarousel.owlCarousel({
            nav: false,
            dots: true,
            loop: true,
            autoplay: true,
            autoplayHoverPause: true,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                },
                640: {
                    items: 2,
                },
                768: {
                    items: 2,
                },
                1024: {
                    items: 3,
                },
                1280: {
                    items: 3,
                },
            },
        })
        topServicesCarousel.parent('.carousel-wrapper').find('.carousel-nav').removeClass('hidden')
        topServicesCarousel
            .parent('.carousel-wrapper')
            .find('.carousel-prev')
            .click(function () {
                topServicesCarouselInstance.trigger('prev.owl.carousel')
            })
        topServicesCarousel
            .parent('.carousel-wrapper')
            .find('.carousel-next')
            .click(function () {
                topServicesCarouselInstance.trigger('next.owl.carousel')
            })
    }

    // Feedback Carousel
    var feedbackCarousel = $('#feedbackCarousel')
    if (feedbackCarousel.length) {
        var feedbackCarouselInstance = feedbackCarousel.owlCarousel({
            nav: false,
            dots: true,
            loop: true,
            autoplay: true,
            autoplayHoverPause: true,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                },
                640: {
                    items: 1,
                },
                768: {
                    items: 1,
                },
                1024: {
                    items: 2,
                },
                1280: {
                    items: 2,
                },
            },
        })
        feedbackCarousel.parent('.carousel-wrapper').find('.carousel-nav').removeClass('hidden')
        feedbackCarousel
            .parent('.carousel-wrapper')
            .find('.carousel-prev')
            .click(function () {
                feedbackCarouselInstance.trigger('prev.owl.carousel')
            })
        feedbackCarousel
            .parent('.carousel-wrapper')
            .find('.carousel-next')
            .click(function () {
                feedbackCarouselInstance.trigger('next.owl.carousel')
            })
    }
}

export default home
