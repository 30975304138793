document.addEventListener('copy', function () {
    // Get the selected text and append the extra info
    var selection = window.getSelection(),
        copyright = '<br /><br /> Source: ' + document.location.href + '<br />===============================<br />Học Viện Soul Plan<br />Website: https://soulplan.org',
        copytext = selection + copyright,
        newdiv = document.createElement('div')

    // Hide the newly created container
    newdiv.style.position = 'absolute'
    newdiv.style.left = '-99999px'

    // Insert the container, fill it with the extended text, and define the new selection
    document.body.appendChild(newdiv)
    newdiv.innerHTML = copytext
    selection.selectAllChildren(newdiv)

    // Clear
    window.setTimeout(function () {
        document.body.removeChild(newdiv)
    }, 100)
})
