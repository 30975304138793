import './copy'

// jQuery
import $ from 'jquery'
window.$ = $

// Owls carousel
import 'owl.carousel'

// Sub scripts
import header from './header'
import home from './home'
import method from './method'
import training from './training'
import service from './service'

$(function () {
    // Header scripts
    header()

    // Home page
    if (appInitialData.view === 'home/index') {
        home()
    }

    // Method page
    if (appInitialData.view === 'method/index') {
        method()
    }

    // Training page
    if (appInitialData.view === 'training/index') {
        training()
    }

    // Service page
    if (appInitialData.view === 'service/index') {
        service()
    }
})
